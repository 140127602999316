<template>
  <div class="bg-rose-900">
    <div class="min-h-screen flex items-center justify-center">
        <div class="max-w-2xl w-full px-4">
            <h1 class="text-4xl font-bold text-center mb-8 text-gray-900 dark:text-white">Coming Soon!</h1>
            <p class="text-lg text-gray-600 dark:text-gray-300 text-center mb-12">Little Red's is under construction. We'll be back soon!
            </p>
        </div>
    </div>
  </div>
</template>
